<template>
  <s-button
    variant="primary"
    size="md"
    class="!min-w-[11.6rem] absolute right-0 top-[-85px]"
    @click="onClickCreate"
  >
    {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_register_btn') }}
  </s-button>
  <div v-show="!isLoading">
    <st-box v-if="noRegisterDiscounts">
      <div class="flex gap-12 items-center mb-20">
        <radio-buttons
          v-model="discountType"
          containerClass="flex gap-8"
          name="discountType"
          :options="options"
          @update:modelValue="onDiscountTypeChange"
        />
      </div>
      <st-table
        :data="discountList"
        :columns="columns"
        :paging="{ total, pageSize: DEFAULT_PAGE_SIZE, show: true }"
        @onRowClick="onClickDiscount"
        @pageChange="onPageChange"
      >
        <template #col-0="{ row }">
          <st-td>
            <span
              class="text-md font-medium"
              :class="{ 'text-brand-primary': row.discountStatus === DISCOUNT_STATUS.IN_PROGRESS }"
            >
              {{ getDiscountStatusText(row.discountStatus) }}
            </span>
          </st-td>
        </template>
        <template #col-5="{ row }">
          <st-td onlyButton>
            <s-button
              v-if="row.discountStatus === DISCOUNT_STATUS.IN_PROGRESS"
              variant="outline"
              size="sm"
              @click="(e: Event) => suspendDiscount(e, row.discountNo)"
            >
              {{ t('studio.prj_prod.this_prod.prod_mgmt_discount_list_suspend_btn') }}
            </s-button>
            <s-button
              v-else
              variant="outline"
              size="sm"
              :disabled="row.discountStatus === DISCOUNT_STATUS.FINISHED"
              @click="(e: Event) => deleteDiscount(e, row.discountNo)"
            >
              {{ t('studio.prj_prod.this_prod.prod_mgmt_discount_list_delete_btn') }}
            </s-button>
          </st-td>
        </template>
        <template #empty>
          <tr>
            <st-td colspan="6">
              <div class="flex justify-center items-center mt-8 min-h-[35rem]">
                <p class="text-md leading-lg text-on-surface-elevation-2 text-center">
                  {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_list_filter_no_data') }}
                </p>
              </div>
            </st-td>
          </tr>
        </template>
      </st-table>
    </st-box>
    <st-box v-else>
      <div class="py-20 flex flex-col gap-20 items-center justify-center">
        <img src="@/assets/images/discount/20-2-2-img.svg" alt="" class="block" />
        <p class="text-3xl font-bold leading-2xl text-on-surface-elevation-1 text-center">
          <safe-html :html="$t('studio.prj_prod.this_prod.prod_mgmt_discount_landing_guide1')" />
        </p>
      </div>
    </st-box>
  </div>
</template>

<script setup lang="ts">
import { ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { deleteProductDiscountApi, fetchProductDiscountListApi } from '@/apis/product-discount.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import SuspendDiscountDialog from '@/components/product-discount/suspend-discount-dialog.vue';
import RadioButtons from '@/components/validation/radio-buttons.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { MODE, SORT_DIRECTION, SORT_VALUE } from '@/constants/common.const';
import { DATE_TIME_FORMAT_WITH_DOT } from '@/constants/date-time.const';
import { DISCOUNT_TYPE } from '@/constants/discount.const';
import { COMMON_ERROR_MESSAGE_KEY } from '@/constants/error.const';
import { DISCOUNT_STATUS } from '@/constants/products-discount.const';
import { DEFAULT_PAGE_SIZE } from '@/constants/table.const';
import { PRODUCT_DISCOUNT_PAGE_URL } from '@/constants/url.const';
import type { FormOption } from '@/types/common/form.type';
import type { ProductDiscountListRequestModel } from '@/types/product-discount/product-discount-request.type';
import type {
  ProductDiscountListItem,
  ProductDiscountListResponseModel
} from '@/types/product-discount/product-discount-response.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import { redirectTo } from '@/utils/common.util';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { formatDateTime } from '@/utils/date.util';

const { checkProductPermission } = useApp();

const route = useRoute();
const { t } = useI18n();

const productId = Number(route.params.productId);

// If product has discount, show discount list
const noRegisterDiscounts = ref<boolean>(false);

const isLoading = ref<boolean>(true);
const discountType = ref<string>(DISCOUNT_TYPE.ALL);
const discountList = ref<ProductDiscountListItem[]>([]);
const total = ref<number>(0);
const currentPage = ref<number>(1);
const options = ref<FormOption[]>([
  {
    label: t('studio.prj_prod.this_prod.prod_mgmt_discount_list_filter_all_msg'),
    value: DISCOUNT_TYPE.ALL
  },
  {
    label: t('studio.prj_prod.this_prod.prod_mgmt_discount_list_filter_active_or_upcoming_msg'),
    value: DISCOUNT_TYPE.APPLYING
  },
  {
    label: t('studio.prj_prod.this_prod.prod_mgmt_discount_list_filter_expired_msg'),
    value: DISCOUNT_TYPE.END
  }
]);

const REQUEST_STATUS = {
  [DISCOUNT_TYPE.ALL]: `${DISCOUNT_STATUS.SCHEDULED},${DISCOUNT_STATUS.IN_PROGRESS},${DISCOUNT_STATUS.FINISHED}`,
  [DISCOUNT_TYPE.APPLYING]: `${DISCOUNT_STATUS.SCHEDULED},${DISCOUNT_STATUS.IN_PROGRESS}`,
  [DISCOUNT_TYPE.END]: `${DISCOUNT_STATUS.FINISHED}`
};

const REQUEST_SORT = {
  CREATED_AT: 'CREATED_AT',
  STATUS: 'STATUS',
  DISCOUNT_NAME: 'DISCOUNT_NAME',
  DISCOUNT_RATE: 'DISCOUNT_RATE',
  REMAIN_COUNT: 'REMAIN_COUNT',
  START_DATE: 'START_DATE'
};

const discountRequest = ref<ProductDiscountListRequestModel>({
  status: REQUEST_STATUS[DISCOUNT_TYPE.ALL],
  sort: REQUEST_SORT.CREATED_AT,
  direction: '',
  page: currentPage.value,
  size: DEFAULT_PAGE_SIZE
});

const getDiscountList = async () => {
  const res = (await fetchProductDiscountListApi(
    productId,
    discountRequest.value
  )) as ProductDiscountListResponseModel;

  if (res) {
    discountList.value = res.contents;
    total.value = res.totalElements;
  }
};

const onDiscountTypeChange = async (value: string | number) => {
  discountRequest.value.status = REQUEST_STATUS[value];
  getDiscountList();
};

const onPageChange = async (page: number) => {
  discountRequest.value.page = page;
  getDiscountList();
};

const onSortChange = async (v: ColumnSorting) => {
  if (v.sortValue === SORT_VALUE.NONE) {
    discountRequest.value.sort = REQUEST_SORT.CREATED_AT;
    discountRequest.value.direction = SORT_DIRECTION.DESC;
  } else {
    discountRequest.value.sort = v.sortKey;
    discountRequest.value.direction =
      v.sortValue === SORT_VALUE.DESC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
  }
  getDiscountList();
};

const getDiscountStatusText = (discountStatus: string) => {
  switch (discountStatus) {
    case DISCOUNT_STATUS.SCHEDULED:
      return t('studio.prj_prod.this_prod.prod_mgmt_discount_status_upcoming_msg');
    case DISCOUNT_STATUS.IN_PROGRESS:
      return t('studio.prj_prod.this_prod.prod_mgmt_discount_status_active_msg');
    case DISCOUNT_STATUS.FINISHED:
      return t('studio.prj_prod.this_prod.prod_mgmt_discount_status_expired_msg');
    default:
      return '';
  }
};

const columns: Column[] = [
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_status_header1'),
    dataIndex: 'status',
    width: '12rem',
    sortKey: REQUEST_SORT.STATUS,
    titleTooltip: t('studio.prj_prod.this_prod.prod_mgmt_discount_status_header1'),
    onSort: onSortChange,
    cellClass: 'px-12 py-16'
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_name_header2'),
    dataIndex: 'discountName',
    width: 'auto',
    sortKey: REQUEST_SORT.DISCOUNT_NAME,
    titleTooltip: t('studio.prj_prod.this_prod.prod_mgmt_discount_name_header2'),
    onSort: onSortChange,
    cellClass: 'px-12 py-16'
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_discount_rate_header3'),
    dataIndex: 'defaultDiscountRate',
    width: '12rem',
    sortKey: REQUEST_SORT.DISCOUNT_RATE,
    render: (row: ProductDiscountListItem) => `${row.defaultDiscountRate}%`,
    titleTooltip: t('studio.prj_prod.this_prod.prod_mgmt_discount_discount_rate_header3'),
    onSort: onSortChange,
    cellClass: 'px-12 py-16'
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_remaining_quantity_header4'),
    dataIndex: 'salesRemainCount',
    width: '12rem',
    sortKey: REQUEST_SORT.REMAIN_COUNT,
    render: (row: ProductDiscountListItem) =>
      row.salesRemainCount === null || row.salesRemainCount === undefined
        ? '-'
        : `${formatNumberMultipleWithCommas(row.salesRemainCount)}`,
    titleTooltip: t('studio.prj_prod.this_prod.prod_mgmt_discount_remaining_quantity_header4'),
    onSort: onSortChange,
    cellClass: 'px-12 py-16'
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_period_header5'),
    dataIndex: 'period',
    width: '15.4rem',
    sortKey: REQUEST_SORT.START_DATE,
    render: (row: ProductDiscountListItem) => {
      const startDate = formatDateTime(row.startAt, DATE_TIME_FORMAT_WITH_DOT);
      const endDate = formatDateTime(row.endAt, DATE_TIME_FORMAT_WITH_DOT);
      return `${startDate}~ ${endDate}`;
    },
    onSort: onSortChange,
    cellClass: 'px-12 py-16'
  },
  {
    title: '',
    dataIndex: 'action',
    width: '8.8rem',
    sortKey: 'action',
    cellClass: 'px-12 py-16'
  }
];

const showDeleteMessageAlert = (success: boolean) => {
  if (success) {
    showAlert({
      content: t('studio.common.popup_case_f_complete_del'),
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });
  } else {
    showAlert({
      content: t(COMMON_ERROR_MESSAGE_KEY),
      severity: 'info',
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!w-full !max-w-full'
    });
  }
};

const suspendDiscount = async (e: Event, discountNo: number) => {
  e.preventDefault();
  e.stopPropagation();
  if (!(await checkProductPermission())) {
    return;
  }
  const result = await showDialog({
    component: shallowRef(SuspendDiscountDialog),
    severity: 'info'
  });
  if (result) {
    const res = await deleteProductDiscountApi(productId, discountNo);
    // showDeleteMessageAlert(res?.success || false);

    if (res?.success) {
      getDiscountList();
    }
  }
};

const deleteDiscount = async (e: Event, discountNo: number) => {
  e.preventDefault();
  e.stopPropagation();
  if (!(await checkProductPermission())) {
    return;
  }
  const result = await showConfirm({
    content: t('studio.prj_prod.this_prod.prod_mgmt_discount_list_delete_pop_msg'),
    contentClasses: 'h-72 flex items-center justify-center mt-24',
    confirmLabel: t('studio.prj_prod.this_prod.prod_mgmt_discount_list_delete_pop_delete_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    confirmVariant: 'red',
    cancelVariant: 'outline'
  });
  if (result) {
    const res = await deleteProductDiscountApi(productId, discountNo);
    showDeleteMessageAlert(res?.success || false);

    if (res?.success) {
      getDiscountList();
    }
  }
};

const onClickCreate = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  navigateToDiscountDetails(MODE.CREATE);
};

const onClickDiscount = async (discount: ProductDiscountListItem) => {
  if (!discount.discountNo) {
    return;
  }

  navigateToDiscountDetails('', discount.discountNo);
};

const navigateToDiscountDetails = async (mode: string, discountNo?: number) => {
  if (mode === MODE.CREATE) {
    redirectTo(`${PRODUCT_DISCOUNT_PAGE_URL}/register`);
  } else {
    redirectTo(`${PRODUCT_DISCOUNT_PAGE_URL}/${discountNo}`);
  }
};

const init = async () => {
  await getDiscountList();
  noRegisterDiscounts.value = discountList.value.length > 0;
  isLoading.value = false;
};

init();
</script>
